import * as React from "react"
import loadable from "@loadable/component"
import * as styles from "../../styles/old.home.module.css";
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { StaticImage } from "gatsby-plugin-image"
const Layout = loadable(()=>import("../../hoc/Layout/Layout"));
const HeaderCard = loadable(()=> import( "../../components/cards/headerCard/HeaderCard"));
const SecondaryCard = loadable(()=> import("../../components/cards/secondaryCard/SecondaryCard"));

// markup
const IndexPage = () => {
  return (
    <Layout>
      <GatsbySeo
          language='en'
          title='Not found - Raysummee'
          noindex={true}
          nofollow={true}
      />
      <main className={styles.home}>
        {/* <div className={styles.gutter_20}/> */}
        <StaticImage 
          src="../../images/center profile.png" 
          placeholder="none"
          className={styles.profile}
          imgStyle={{ 
            objectFit: 'contain' ,
          }}
          layout="fullWidth"
          alt="photo of angshuman barpujari"
        />
        
        <HeaderCard
          className={styles.card}
          title={`Angshuman\nBarpujari`}
          subtext={`Hello, I am`}
        />

        <SecondaryCard
          className={styles.second_card}
          title={`Flutter Developer`}
          subtitle={`UI/UX Designer`}
        />
      </main>
    </Layout>
  )
}

export default IndexPage
